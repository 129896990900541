// File: src/components/Controls.js
import React from 'react';
import '../styles/components/Controls.css';

const Controls = ({
                      style,
                      setStyle,
                      loading,
                      options,
                      showResult, // Receive the prop
                  }) => {
    return (
        <div className={`controls-component`}>
            {/* Style Selection Box */}
            <div className="style-selection">
                {/*<label htmlFor="style-select">Select Style:</label>*/}
                <br></br>
                <select
                    id="style-select"
                    value={style}
                    onChange={(e) => setStyle(e.target.value)}
                    disabled={loading || showResult} // Disable when loading or showResult is true
                >
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
            {/* Other Control Elements if any */}
        </div>
    );
};

export default Controls;
