// src/axiosConfig.js
import axios from 'axios';
import Cookies from 'js-cookie';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        const token = Cookies.get('google_id_token');
        const user_email = Cookies.get('user_email');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        if (user_email) {
            config.headers['User-Email'] = user_email;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
