// File: src/components/ImageConfirmation.js
import React from 'react';

const ImageConfirmation = ({ onSubmit, onRetake }) => {
    return (
        <div className="image-confirmation">
            <button onClick={onSubmit} className="btn btn-primary">
                ✅ Submit
            </button>
            <button onClick={onRetake} className="btn btn-secondary">
                🔄 Retake
            </button>
        </div>
    );
};

export default ImageConfirmation;
