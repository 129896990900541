// File: src/main_pages/HelpMeChoosePage.js
import React, { useState, useRef } from 'react';
import Header from '../components/Header';
import VideoContainer from '../components/VideoContainer';
import TopMenu from '../components/TopMenu';
import TextResult from '../components/TextResult';
import EventSelection from '../components/EventSelection';
import AudioPlayer from "../components/AudioPlayer";
import ImagesPreview from "../components/ImagesPreview";
import ShareButton from "../components/ShareButton";
import ApiService from '../services/apiService';
import CommonPage from "./CommonPage";

const HelpMeChoosePage = () => {

    const promptOptions = [
        { value: 'judge_real', label: 'Real Judge' },
    ];

    return (
        <div>
            <CommonPage
                promptOptions={promptOptions}
                showPromptOptions={false}
                fetchRecommendations={false}
                pageDescription="Compare Outfits"
                uploadMethod={ApiService.compareOutfits}
            />
        </div>
    );
};

export default HelpMeChoosePage;
