// File: src/main_pages/StyleMeterPage.js
import React, { useState, useRef } from 'react';
import ApiService from '../services/apiService';
import Header from '../components/Header';
import VideoContainer from '../components/VideoContainer';
import TextResult from '../components/TextResult';
import TopMenu from '../components/TopMenu';
import AudioPlayer from "../components/AudioPlayer";
import ShareButton from "../components/ShareButton";
import ImagesPreview from "../components/ImagesPreview";
import config from "../config";
import CommonPage from "./CommonPage";

const StyleMeterPage = () => {
    const promptOptions = [
        { value: 'judge_real', label: 'Real Judge' },
        { value: 'judge_nice', label: 'Nice Judge' },
        { value: 'judge_nasty', label: 'Nasty Judge 😡' },
    ];

    return (
        <div >
            <CommonPage
                promptOptions={promptOptions}
                fetchRecommendations={false}
                pageDescription="Let the Style Meter Rate Your Outfit"
                showEventSelection={true}
                showPromptOptions={false}
            />
        </div>
    );
};

export default StyleMeterPage;
