// src/components/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';

const Layout = ({ language, setLanguage }) => {
    return (
        <div>
            {/* Top Menu or Header */}
            <header>
                {/* Your top menu components */}
                <h1>Magic Mirror</h1>
                {/* Language Selector, etc. */}
            </header>

            {/* Main Content */}
            <main>
                <Outlet /> {/* This is where child routes will be rendered */}
            </main>

            {/* Footer */}
            <footer>
                {/* Your footer components */}
                <p className="text-center">&copy; 2024 Magic Mirror AI</p>
            </footer>
        </div>
    );
};

export default Layout;
