// File: src/components/MultiStepCTA.js
import React, { useContext, useState } from 'react';
import EventContext from '../context/EventContext';
import { useNavigate } from 'react-router-dom';

const MultiStepCTA = ({ isSubmitting, onQuickCapture }) => {
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    const { event, setEvent } = useContext(EventContext);


    const handleSelectMainOption = (option) => {
        if (option === 'flattering' || option === 'negative') {
            // Set style and navigate with autoCapture and event
            navigate('/mirror', { state: { autoCapture: true, style: option, event } });
        } else if (option === 'styleMeter') {
            setStep(2); // Proceed to event selection for style meter
        } else if (option === 'dressMe') {
            setStep(2); // Proceed to event selection for dress me
        }
    };

    const handleEventSelect = (selectedEvent) => {
        if (selectedEvent === 'More') {
            setStep(3); // Move to "More Event Options" step
        } else {
            setEvent(selectedEvent); // Update the event state
            // Navigate based on the last main option chosen
            if (step === 2) {
                if (selectedEvent) {
                    navigate('/dresser', { state: { autoCapture: true, style: 'honest', event: selectedEvent } });
                }
            } else if (step === 3) {
                if (selectedEvent) {
                    navigate('/style-meter', { state: { autoCapture: true, style: 'judge_real', event: selectedEvent } });
                }
            }
        }
    };

    return (
        <div className="multi-step-cta">
            {/* Main Options Step */}
            {step === 1 && (
                <div className="cta-step">
                    <button onClick={() => handleSelectMainOption('flattering')} className="btn btn-info" disabled={isSubmitting}>
                        ❤️ Good Vibes ❤️
                    </button>
                    <button onClick={() => handleSelectMainOption('styleMeter')} className="btn btn-secondary" disabled={isSubmitting}>
                        ⭐️ Style Meter ⭐️
                    </button>
                    <button onClick={() => handleSelectMainOption('dressMe')} className="btn btn-success" disabled={isSubmitting}>
                        👗 Dress Me 👗
                    </button>
                    <button onClick={() => handleSelectMainOption('negative')} className="btn btn-info" disabled={isSubmitting}>
                        🤓 Honest 🤓
                    </button>
                </div>
            )}

            {/* Event Selection Step */}
            {step === 2 && (
                <div className="cta-step">
                    <h3>Select Event</h3>
                    <button onClick={() => handleEventSelect('Casual')} className="event-option">Casual</button>
                    <button onClick={() => handleEventSelect('Formal')} className="event-option">Formal</button>
                    <button onClick={() => handleEventSelect('Sports')} className="event-option">Sports</button>
                    <button onClick={() => handleEventSelect('Party')} className="event-option">Party</button>
                    <button onClick={() => handleEventSelect('More')} className="event-option">More</button>
                    <button onClick={() => setStep(1)} className="event-option cancel">Back</button>
                </div>
            )}

            {/* More Event Options Step */}
            {step === 3 && (
                <div className="cta-step">
                    <h3>More Event Options</h3>
                    <button onClick={() => handleEventSelect('Wedding')} className="event-option">Wedding</button>
                    <button onClick={() => handleEventSelect('Beach')} className="event-option">Beach</button>
                    <button onClick={() => handleEventSelect('Night Out')} className="event-option">Night Out</button>
                    <button onClick={() => handleEventSelect('Other')} className="event-option">Other</button>
                    <button onClick={() => setStep(2)} className="event-option cancel">Back</button>
                </div>
            )}
        </div>
    );
};

export default MultiStepCTA;
