// src/services/logoutService.js
import Cookies from 'js-cookie';

export const logout = () => {
    // Remove the authentication cookies
    Cookies.remove('google_id_token');
    Cookies.remove('user_email');

    // Optionally, redirect to login page or home page
    window.location.href = '/login';
};
