import React from 'react';
import { RWebShare } from 'react-web-share';
import config from "../config";
import '../styles/components/ShareButton.css';

const ShareButton = ({ taskId, title, text }) => {
    const url = `${config.frontend_hostname}/result/${taskId}`;
    return (
        <div className="share-button-container mt-4">
            <RWebShare
                data={{
                    text: text,
                    url: url,
                    title: title,
                }}
                onClick={() => console.log("Shared data:", { url, title, text })}
            >
                <button className="btn btn-primary">Share</button>
            </RWebShare>
        </div>
    );
};

export default ShareButton;
