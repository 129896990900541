// File: src/components/AudioPlayer.js
import React from 'react';
import '../styles/components/AudioPlayer.css';

const AudioPlayer = ({ audioSrc }) => {
    return (
        <div className="audio-container d-flex align-items-center justify-content-center">
            <audio src={audioSrc} controls autoPlay className="mt-3 w-100"></audio>
        </div>
    );
};

export default AudioPlayer;
