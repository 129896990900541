// File: src/components/ImagesPreview.js
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import '../styles/components/ImagesPreview.css';

const ImagesPreview = ({ images, clearImages, handleImageRemove }) => {
    useEffect(() => {
        // Cleanup object URLs to prevent memory leaks
        return () => {
            images.forEach(image => {
                if (image instanceof Blob) {
                    URL.revokeObjectURL(image);
                }
            });
        };
    }, [images]);

    if (images.length === 0) return null;

    return (
        <div className="images-preview-container">
            {images.map((image, index) => (
                <div key={index} className="image-wrapper">
                    <img
                        src={image instanceof Blob ? URL.createObjectURL(image) : image}
                        alt={`Uploaded ${index + 1}`}
                        className="preview-image"
                    />
                    <button
                        className="delete-image-button"
                        onClick={() => handleImageRemove(index)}
                        aria-label={`Delete image ${index + 1}`}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            ))}
        </div>
    );
};

export default ImagesPreview;
