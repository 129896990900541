// File: src/config.js

const getEnvVariable = (key, defaultValue) => {
  return process.env[key] || defaultValue;
};

const config = {
  backend_hostname: getEnvVariable('REACT_APP_BACKEND_HOSTNAME', 'https://magic-backend-1068119864554.us-central1.run.app'),
  frontend_hostname: getEnvVariable('REACT_APP_FRONTEND_HOSTNAME', 'https://magic-frontend-1068119864554.us-central1.run.app'),
  wordpressUrl: getEnvVariable('REACT_APP_WORDPRESS_URL', 'https://magicmirrorai.co.uk'),
  wordpressIssueTokenUrl: getEnvVariable('REACT_APP_WORDPRESS_ISSUE_TOKEN_URL', 'https://magicmirrorai.co.uk/wp-json/api/v1/token'),
  wordpressTokenVerifyUrl: getEnvVariable('REACT_APP_WORDPRESS_TOKEN_VERIFY_URL', 'https://magicmirrorai.co.uk/wp-json/api/v1/token-validate?mo_rest_api_test_config=jwt_auth'),
  clarityProjectId: getEnvVariable('REACT_APP_CLARITY_PROJECT_ID', 'ovhufs8lph'),
  googleClientId: getEnvVariable('REACT_APP_GOOGLE_CLIENT_ID', '1068119864554-peraplo6rt8db9ev5mg92b4ndp885v9q.apps.googleusercontent.com'),
};

export default config;
