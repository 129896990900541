import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ShareButton from '../components/ShareButton';
import axiosInstance from '../axiosConfig';
import config from '../config';
import TopMenu from '../components/TopMenu';
import AudioPlayer from '../components/AudioPlayer';
import Message from '../components/Message';
import useAutoDismissMessage from '../hooks/useAutoDismissMessage';
import '../styles/pages/resultPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ResultPage = () => {
    const { taskId } = useParams();
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const { message, type, showMessage } = useAutoDismissMessage();

    useEffect(() => {
        if (!taskId) return; // Ensure taskId is available before fetching

        const fetchResult = async () => {
            try {
                setLoading(true); // Set loading state before the fetch
                const response = await axiosInstance.get(`${config.backend_hostname}/results/${taskId}`);
                console.log('Result:', response.data);
                setResult(response.data);
            } catch (error) {
                console.error('Failed to fetch result:', error);
                showMessage('Failed to fetch result.', 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchResult();
    }, [taskId]); // Only taskId as dependency, excluding showMessage

    if (loading) {
        return <div>Loading...</div>;
    }

    if (message && type === 'error') {
        return (
            <div className="main-container">
                <TopMenu />
                <Message message={message} type={type} />
            </div>
        );
    }

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % result.images.length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + result.images.length) % result.images.length);
    };

    const title = 'Check out my result on Magic Mirror!';
    const text = 'I got amazing feedback from Magic Mirror!';

    return (
        <div className="main-container result-page">
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:description" content={text} />
                <meta property="og:image" content={result.images[0]} />
                <meta property="og:url" content={`${config.frontend_hostname}/result/${taskId}`} />
                <meta property="og:type" content="website" />
            </Helmet>
            <TopMenu />
            <div className="content">
                <h1>Your Result</h1>
                {message && <Message message={message} type={type} />}
                <div className="image-slider">
                    {result.images.length > 0 && (
                        <div className="slider-container">
                            <button onClick={handlePrevImage} className="slider-button left">
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                            <img
                                src={result.images[currentImageIndex]}
                                alt="Result"
                                className="result-image"
                            />
                            <button onClick={handleNextImage} className="slider-button right">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    )}
                </div>
                <p className="result-text">{result.text}</p>
                {result.audio && <AudioPlayer audioSrc={result.audio} />}
                <ShareButton
                    taskId={taskId}
                    title={title}
                    text={text}
                />
            </div>
        </div>
    );
};

export default ResultPage;
