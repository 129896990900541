import React from 'react';
import '../styles/pages/recommendations.css';

const Recommendations = ({ recommendations }) => {
  return (
    <div className="recommendations-container mt-4">
      <h2 className="text-primary">Recommended Products</h2>
      <div className="row">
        {recommendations.map((item, index) => (
          <div className="col-md-4 d-flex align-items-stretch" key={index}>
            <div className="card mb-4 shadow-sm d-flex align-items-stretch">
              <img src={item.images[0]} className="card-img-top mini-img" alt={item.name} />
              <div className="card-body">
                <h5 className="card-title">{item.name}</h5>
                <p className="card-text">{item.details}</p>
                <p className="card-text"><strong>{item.price}</strong></p>
                <a href={item.link} className="btn btn-primary btn-sm" target="_blank" rel="noopener noreferrer">View Product</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Recommendations;
