// File: src/pages/MyProfilePage.js
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ApiService from '../services/apiService'; // Import the updated ApiService
import '../styles/pages/myProfile.css';
import TopMenu from "../components/TopMenu"; // Ensure this path is correct

const MyProfilePage = () => {
    const [profileImage, setProfileImage] = useState(null); // Store blob URL
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [reloadKey, setReloadKey] = useState(Date.now());
    const fileInputRef = useRef(null);
    const [imageError, setImageError] = useState('');

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const blob = await ApiService.fetchProfileImage();
                const url = URL.createObjectURL(blob);
                setProfileImage(url);
            } catch (err) {
                console.error('Failed to fetch profile image:', err);
                setImageError('Failed to load profile image.');
            }
        };

        fetchImage();

        // Cleanup: revoke object URL when component unmounts or image changes
        return () => {
            if (profileImage) {
                URL.revokeObjectURL(profileImage);
            }
        };
    }, [reloadKey]);

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            if (file.size > 5 * 1024 * 1024) { // 5MB limit
                alert('File size exceeds the 5MB limit.');
                return;
            }
            setIsUploading(true);
            setError('');
            try {
                await ApiService.uploadProfileImage(file);
                // Update the reloadKey to refresh the image
                const newReloadKey = Date.now();
                setReloadKey(newReloadKey);
            } catch (err) {
                setError('Failed to upload image. Please try again.');
            } finally {
                setIsUploading(false);
            }
        } else {
            alert('Please select a valid image file.');
        }
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="my-profile-page">
            {/*<div className="main-container d-flex flex-column align-items-center justify-content-start text-center p-3">*/}
            <TopMenu />
            <div className="my-profile-content">
                <div className="profile-image-container">
                    {profileImage ? (
                        <img
                            src={profileImage}
                            alt="User Profile"
                            className="profile-image"
                        />
                    ) : (
                        <img
                            src="/images/default-profile.png"
                            alt="Default User Profile"
                            className="profile-image"
                        />
                    )}
                    <button
                        className="edit-button"
                        onClick={triggerFileInput}
                        aria-label="Edit Profile Picture"
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                        aria-hidden="true"
                        tabIndex="-1"
                    />
                </div>
                <h2 className="my-profile-title">My Profile</h2>
                <div className="my-profile-links">
                    <Link to="/inventory" className="btn btn-primary">My Inventory</Link>
                    <Link to="/measurements" className="btn btn-primary">My Measurements</Link>
                </div>
                <div className="additional-profile-settings">
                    {/* Additional profile settings forms or links can be added here */}
                    <p>Manage your profile settings below.</p>
                </div>
                {isUploading && <p className="upload-status">Uploading...</p>}
                {error && <p className="error-message">{error}</p>}
                {imageError && <p className="error-message">{imageError}</p>}
            </div>
        </div>
    );
};

export default MyProfilePage;
