// File: src/main_pages/CommonPage.js
import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import ApiService from '../services/apiService';
import Header from '../components/Header';
import VideoContainer from '../components/VideoContainer';
import Controls from '../components/Controls';
import TextResult from '../components/TextResult';
import Recommendations from '../components/Recommendations';
import EventSelection from '../components/EventSelection';
import TopMenu from '../components/TopMenu';
import AudioPlayer from '../components/AudioPlayer';
import ImagesPreview from '../components/ImagesPreview';
import ShareButton from '../components/ShareButton';
import config from '../config';
import '../styles/components/CommonPage.css'; // Import CSS for transitions

const CommonPage = ({
                        promptOptions,
                        fetchRecommendations,
                        pageDescription,
                        uploadMethod = ApiService.uploadPhoto,
                        showEventSelection = true,
                        showImagePreview = true,
                        showPromptOptions = true,
                        showCallToActions = false
                    }) => {
    const location = useLocation();
    const [language, setLanguage] = useState('en');
    const [style, setStyle] = useState(promptOptions[0].value);
    const [event, setEvent] = useState('Casual');
    const [audioSrc, setAudioSrc] = useState(null);
    const [textResult, setTextResult] = useState('');
    const [recommendations, setRecommendations] = useState([]);
    const [taskId, setTaskId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [images, setImages] = useState([]);
    const [showResult, setShowResult] = useState(false); // New state variable

    const resultViewsRef = useRef(null); // Ref for result views
    const videoContainerRef = useRef(null); // Reference to trigger capture in VideoContainer

    useEffect(() => {
        if (location.state?.autoCapture) {
            const captureStyle = location.state.style || 'honest'; // Set the style from state if provided
            setStyle(captureStyle);

            // Wait for the VideoContainer to mount before triggering capture
            setTimeout(() => {
                if (videoContainerRef.current) {
                    videoContainerRef.current.handleCaptureClick(3000); // Trigger quick capture (3 seconds delay)
                }
            }, 100); // Adjust this delay if needed
        }
    }, [location.state]);

    useEffect(() => {
        // Scroll to the bottom of result views when textResult changes
        if (textResult && resultViewsRef.current) {
            resultViewsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [textResult]);

    const handleEventSelect = (selectedEvent) => {
        setEvent(selectedEvent); // Update event state when selected
    };

    const uploadPhoto = async (images) => {
        try {
            setAudioSrc(null);
            setTextResult('');
            setRecommendations([]);
            const data = await uploadMethod(images, style, language, event);
            const { audio, text, task_id } = data;
            setAudioSrc(audio);
            setTextResult(text);
            setTaskId(task_id);
            if (fetchRecommendations) {
                await loadRecommendations(task_id);
            }
            setShowResult(true); // Set showResult to true after receiving result
        } catch (error) {
            console.error('Error uploading photo:', error);
        } finally {
            setLoading(false);
        }
    };

    const loadRecommendations = async (task_id) => {
        setLoadingMore(true);
        try {
            const url = `${config.backend_hostname}/recommendations/${task_id}`;
            const response = await axiosInstance.post(url, { language }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const recommendations = response.data;
            setRecommendations((prevRecommendations) => [...prevRecommendations, ...recommendations]);
        } catch (error) {
            console.error('Error getting recommendations:', error);
        } finally {
            setLoadingMore(false);
            setLoading(false);
        }
    };

    const handleMoreClick = () => {
        if (taskId) {
            loadRecommendations(taskId);
        }
    };

    const handleRestartClick = () => {
        window.location.reload();
    };

    return (
        <div className="main-container">
            <TopMenu language={language} setLanguage={setLanguage} handleRestartClick={handleRestartClick} />
            {pageDescription && <p className="page-description">{pageDescription}</p>}

            {/* Controls and VideoContainer with Fade-Out */}
            <div className={`controls-video-container`}>
                <VideoContainer
                    ref={videoContainerRef}
                    uploadMethod={uploadPhoto}
                    actionText="Submit Photos"
                    setImages={setImages}
                    showImagePreview={showImagePreview}
                    showResult={showResult}
                    showEventSelection={showEventSelection}
                    showCallToActions={showCallToActions}
                />
                { showPromptOptions && <Controls
                    style={style}
                    setStyle={setStyle}
                    loading={loading}
                    options={promptOptions}
                    showResult={showResult}
                />}
            </div>
            {/* Render EventSelection and pass handleEventSelect */}
            {showEventSelection && (
                <div className="floating-event-selection">
                    <EventSelection onEventSelect={handleEventSelect} />
                </div>
            )}

            {/* Result Views with Fade-In */}
            {taskId && (
                <ShareButton
                    taskId={taskId}
                    title="Check out my result on Magic Mirror!"
                    text="I got amazing feedback from Magic Mirror!"
                />
            )}

            <div ref={resultViewsRef} className={`result-views ${showResult ? 'fade-in' : 'fade-out'}`}>
                {textResult && <TextResult textResult={textResult} taskId={taskId} />}
                {audioSrc && <AudioPlayer audioSrc={audioSrc} />}
            </div>


            {fetchRecommendations && recommendations.length > 0 && <Recommendations recommendations={recommendations} />}
            {fetchRecommendations && taskId && (
                <button onClick={handleMoreClick} className="btn btn-primary mt-3" disabled={loadingMore}>
                    {loadingMore ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    ) : (
                        'More'
                    )}
                </button>
            )}

        </div>
    );
};

export default CommonPage;
