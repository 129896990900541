import React, { useState, useRef } from 'react';
import CommonPage from './CommonPage';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShirt } from '@fortawesome/free-solid-svg-icons';

const MirrorPage = () => {
  const promptOptions = [
    { value: 'flattering', label: 'Love me ❤️' },
    { value: 'positive', label: 'Positive 😎' },
    { value: 'negative', label: 'Honest 🤓' },
    { value: 'bitch', label: 'Nasty 🤬' },
    { value: 'stylist', label: 'Stylist 🔥' },
  ];

  return (
      <div>
        <CommonPage
            promptOptions={promptOptions}
            fetchRecommendations={false}
            pageDescription="Mirror Mirror On The Wall..."
            showEventSelection={false}
            showImagePreview={false} // Hide image previews
            showCallToActions={true}
        />
      </div>
  );
};

export default MirrorPage;
