// File: src/components/TopMenu.js
import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe, faRedo, faSignOutAlt, faHome,faStar, faWandMagicSparkles,
    faMagic, faTshirt, faUser, faRuler, faBalanceScale,

} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { useNavigate, useLocation } from 'react-router-dom';
import { logout } from '../services/logoutService';
import '../styles/components/TopMenu.css';

const TopMenu = ({ language, setLanguage, handleRestartClick }) => {
    const [showLanguageOptions, setShowLanguageOptions] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleLanguageOptions = () => {
        setShowLanguageOptions((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowLanguageOptions(false);
        }
    };

    useEffect(() => {
        if (showLanguageOptions) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showLanguageOptions]);

    // Detect horizontal scrolling to navigate between screens
    const handleScroll = (event) => {
        if (event.deltaY === 0) { // only respond to horizontal scrolls
            const paths = ["/mirror", "/shopping", "/helpmechoose", "/rankme"];
            const currentIndex = paths.indexOf(location.pathname);
            let newIndex = currentIndex;

            if (event.deltaX > 0) { // scroll right
                newIndex = (currentIndex + 1) % paths.length;
            } else if (event.deltaX < 0) { // scroll left
                newIndex = (currentIndex - 1 + paths.length) % paths.length;
            }

            navigate(paths[newIndex]);
        }
    };

    useEffect(() => {
        window.addEventListener('wheel', handleScroll);
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [location]);

    const handleDeepRefresh = async () => {
        if ('serviceWorker' in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (let registration of registrations) {
                await registration.unregister();
            }
        }
        window.location.reload(true);
    };

    return (
        <div className="top-menu mb-3">
            {/* Navigation Buttons */}
            <div className="nav-buttons">
                <button onClick={() => navigate('/mirror')} className="btn btn-link" title="Magic Mirror">
                    <FontAwesomeIcon icon={faWandMagicSparkles} className="icon" />
                </button>
                <button onClick={() => navigate('/dresser')} className="btn btn-link" title="Dresser">
                    <FontAwesomeIcon icon={faTshirt} className="icon" />
                </button>
                <button onClick={() => navigate('/outfit-chooser')} className="btn btn-link" title="Chooser">
                    <FontAwesomeIcon icon={faBalanceScale} className="icon" />
                </button>
                <button onClick={() => navigate('/style-meter')} className="btn btn-link" title="Style Meter">
                    <FontAwesomeIcon icon={faStar} className="icon" />
                </button>
            </div>

            {/* Additional Controls */}
            <div className="icon-container">
                <button onClick={logout} className="btn btn-link" title="Logout">
                    <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
                </button>
                <button onClick={handleDeepRefresh} className="btn btn-link" title="Refresh">
                    <FontAwesomeIcon icon={faRedo} className="icon" />
                </button>
                <div className="icon-wrapper" title="Change Language">
                    <FontAwesomeIcon icon={faGlobe} className="icon" onClick={toggleLanguageOptions} />
                    {showLanguageOptions && (
                        <div className="dropdown" ref={dropdownRef}>
                            <select
                                value={language}
                                onChange={(e) => {
                                    setLanguage(e.target.value);
                                    toggleLanguageOptions();
                                }}
                            >
                                <option value="en">English</option>
                                <option value="es">Spanish</option>
                                <option value="pt">Portuguese</option>
                                <option value="fr">French</option>
                                <option value="iw">עברית</option>
                            </select>
                        </div>
                    )}
                </div>
                <Link to="/profile" className="btn btn-link" title="Profile">
                    <FontAwesomeIcon icon={faUser} className="icon" />
                </Link>

            </div>
        </div>
    );
};

export default TopMenu;
