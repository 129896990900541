// File: src/components/VideoContainer.js
import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSyncAlt,
    faUpload,
    faCamera,
    faPaperPlane,
    faTrash,
    faChevronLeft,
    faChevronRight,
    faPowerOff
} from '@fortawesome/free-solid-svg-icons';
import '../styles/components/VideoContainer.css';
import ImagesPreview from './ImagesPreview';
import useAutoDismissMessage from '../hooks/useAutoDismissMessage';
import Message from './Message';
import EventSelection from "./EventSelection"; // Ensure EventSelection is imported
import MultiStepCTA from './MultiStepCTA';
import ImageConfirmation from './ImageConfirmation';

const VideoContainer = forwardRef(({ uploadMethod, actionText, showImagePreview = true, showEventSelection = true, showCallToActions = false }, ref) => {
    const [facingMode, setFacingMode] = useState('user');
    const [stream, setStream] = useState(null);
    const [isCameraActive, setIsCameraActive] = useState(() => {
        // Initialize from localStorage or default to true
        return JSON.parse(localStorage.getItem('isCameraActive')) ?? true;
    });
    const [images, setImages] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [countdown, setCountdown] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false); // Show either MultiStepCTA or ImageConfirmation
    const countdownIntervalRef = useRef(null);
    const canvasRef = useRef(null);
    const videoRef = useRef(null);
    const messageRef = useRef(null);
    const selfieCaptureDelay = 10000;
    const quickCaptureDelay = 3000;
    const { message, type, showMessage, clearMessage } = useAutoDismissMessage();

    useImperativeHandle(ref, () => ({
        handleCaptureClick,
    }));

    const startStream = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        navigator.mediaDevices.getUserMedia({ video: { facingMode } })
            .then(stream => {
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.play().catch(error => {
                        console.error('Error trying to play video:', error);
                    });
                };
                setStream(stream);
            })
            .catch(err => {
                console.error('Error accessing camera: ', err);
                alert('Error accessing camera: ' + err.message);
            });
    };

    const toggleCamera = () => {
        if (isCameraActive) {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            setStream(null);
        } else {
            startStream();
        }
        setIsCameraActive(prevState => {
            const newState = !prevState;
            localStorage.setItem('isCameraActive', JSON.stringify(newState)); // Persist to localStorage
            return newState;
        });
    };

    useEffect(() => {
        if (isCameraActive && videoRef && videoRef.current) {
            startStream();
        }
        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
            }
        };
    }, [facingMode, isCameraActive]);

    const handleUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files) {
            setImages((prevImages) => [...prevImages, ...files]);
            setCurrentImageIndex(0);
            setShowConfirmation(true); // Show ImageConfirmation when image is uploaded
        }
    };

    const handleCaptureClick = (captureDelay) => {
        if (canvasRef.current && videoRef.current) {
            if (facingMode === 'user') {
                if (countdown === null && !countdownIntervalRef.current && images.length === 0) {
                    setCountdown(captureDelay / 1000);
                    startCountdown();
                }
            } else {
                captureImage();
            }
        }
    };

    const startCountdown = () => {
        countdownIntervalRef.current = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(countdownIntervalRef.current);
                    countdownIntervalRef.current = null;
                    captureImage();
                    return null;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const captureImage = () => {
        if (images.length > 0) return;
        const canvas = canvasRef.current;
        const video = videoRef.current;
        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
            setImages([blob]);
            setShowConfirmation(true); // Show ImageConfirmation after image is captured
        }, 'image/jpeg');
    };

    const handleSubmit = async () => {
        if (images.length < 1) {
            showMessage('Please upload at least 1 photo', 'error');
            return;
        }
        setIsSubmitting(true);
        try {
            await uploadMethod(images);
            showMessage('Photos uploaded successfully!', 'success');
            setShowConfirmation(false);
        } catch (error) {
            console.error('Error uploading photos:', error);
            showMessage(error.response?.data?.message || 'An unexpected error occurred.', 'error');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSwitchCamera = () => {
        setFacingMode(facingMode === 'user' ? 'environment' : 'user');
        if (countdownIntervalRef.current) {
            clearInterval(countdownIntervalRef.current);
            countdownIntervalRef.current = null;
            setCountdown(null);
        }
    };

    const handleImageRemove = (index) => {
        setImages((prevImages) => {
            const updatedImages = prevImages.filter((_, i) => i !== index);
            if (updatedImages.length === 0) {
                startStream();
            }
            return updatedImages;
        });
        setCurrentImageIndex((prevIndex) => Math.max(0, prevIndex - 1));
    };

    const clearImages = () => {
        setImages([]);
        setCurrentImageIndex(0);
        setShowConfirmation(false); // Reset to MultiStepCTA
        startStream();
    };

    return (
        <div className="w-100 video-container-wrapper">
            <div className="video-container mb-4 border rounded position-relative">
                {message && (
                    <div className="floating-event-selection">
                        <Message message={message} type={type} ref={messageRef} />
                    </div>
                )}
                {/* Camera Toggle Button */}
                <button
                    onClick={toggleCamera}
                    className="camera-toggle-button"
                    title={isCameraActive ? 'Turn Off Camera' : 'Turn On Camera'}
                >
                    <FontAwesomeIcon icon={faPowerOff} />
                </button>

                {images.length === 0 && (
                    <video
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted
                        preload="auto"
                        className="rounded video-element"
                    ></video>
                )}
                {images.length > 0 && (
                    <div className="image-slider">
                        <button onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)} className="slider-button left">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <img
                            src={images[currentImageIndex] instanceof Blob ? URL.createObjectURL(images[currentImageIndex]) : images[currentImageIndex]}
                            alt="Captured"
                            className="rounded captured-image"
                        />
                        <button onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length)} className="slider-button right">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                )}
                <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                {countdown !== null && (
                    <div className="countdown-overlay">
                        <h1>{countdown}</h1>
                    </div>
                )}
                {isSubmitting && (
                    <div className="scan-overlay">
                        <div className="scan-line"></div>
                    </div>
                )}

                <div className="floating-buttons">
                    <button onClick={handleSwitchCamera} className="btn btn-secondary" disabled={isSubmitting}>
                        <FontAwesomeIcon icon={faSyncAlt} />
                    </button>
                    {images.length === 0 ? (
                        <button onClick={() => handleCaptureClick(selfieCaptureDelay)} className="btn btn-primary" disabled={countdown !== null || isSubmitting}>
                            <FontAwesomeIcon icon={faCamera} />
                        </button>
                    ) : (
                        <button onClick={() => clearImages()} className="btn btn-secondary" disabled={isSubmitting}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    )}
                    <label className="btn btn-secondary">
                        <FontAwesomeIcon icon={faUpload} />
                        <input type="file" accept="image/*" multiple onChange={handleUpload} style={{ display: 'none' }} />
                    </label>
                    <button onClick={() => handleSubmit(images)} className="btn btn-primary" disabled={images.length === 0 || isSubmitting}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                </div>

                <div className="confirmation-cta-container">
                    {showCallToActions && !showConfirmation && !countdown && !isSubmitting && (
                        <MultiStepCTA isSubmitting={isSubmitting} onQuickCapture={handleCaptureClick} />
                    )}
                    {showConfirmation && !countdown && !isSubmitting && (
                        <ImageConfirmation
                            onSubmit={handleSubmit}
                            onRetake={clearImages}
                        />
                    )}
                </div>
            </div>
            {showImagePreview && !isSubmitting && (
                <ImagesPreview images={images} clearImages={clearImages} handleImageRemove={handleImageRemove} />
            )}
        </div>
    );
});

export default VideoContainer;
