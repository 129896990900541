// File: src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const ProtectedRoute = () => {
    const location = useLocation();
    const token = Cookies.get('google_id_token');

    if (!token) {
        // Redirect to login page with nextUrl to the current path
        return <Navigate to={`/login?nextUrl=${encodeURIComponent(location.pathname)}`} replace />;
    }

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
            // Token expired, remove token and redirect to login
            Cookies.remove('google_id_token');
            Cookies.remove('user_email');
            return <Navigate to={`/login?nextUrl=${encodeURIComponent(location.pathname)}`} replace />;
        }
    } catch (error) {
        console.error('Invalid token:', error);
        Cookies.remove('google_id_token');
        Cookies.remove('user_email');
        return <Navigate to={`/login?nextUrl=${encodeURIComponent(location.pathname)}`} replace />;
    }

    // User is authenticated
    return <Outlet />;
};

export default ProtectedRoute;
