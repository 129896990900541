// File: src/components/Message.js
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const Message = forwardRef(({ message, type }, ref) => (
    <div ref={ref} className={`message ${type}`}>
      {message}
    </div>
));

Message.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Message;
