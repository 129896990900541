// File: src/App.js
import React, { useState, useEffect } from 'react';
import { initialize } from 'react-native-clarity';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import BrandPage from './main_pages/BrandPage';
import MirrorPage from './main_pages/MirrorPage';
import LoginPage from './pages/LoginPage';
import StyleMeterPage from './main_pages/StyleMeterPage';
import HelpMeChoosePage from './main_pages/HelpMeChoosePage';
import InventoryPage from './pages/InventoryPage';
import Layout from './components/Layout';
import AddItemPage from './pages/AddItemPage';
import MyProfilePage from './pages/MyProfilePage';
import MeasurementsPage from './pages/MeasurementsPage';
import ResultPage from './pages/ResultPage';
import ProtectedRoute from './components/ProtectedRoute';
import config from './config';

// Import the EventProvider to wrap the entire app
import { EventProvider } from './context/EventContext';

const App = () => {
    const [language, setLanguage] = useState('en');

    useEffect(() => {
        // Initialize Microsoft Clarity with your Project ID
        initialize(config.clarityProjectId);
    }, []);

    return (
        <EventProvider> {/* Wrap the app with EventProvider */}
            <Router>
                <Routes>
                    {/* Public Routes */}
                    <Route path="/login" element={<LoginPage />} />

                    {/* Protected Routes Wrapped with Layout */}
                    <Route element={<Layout language={language} setLanguage={setLanguage} />}>
                        {/* Redirect root to /shopping */}
                        <Route index element={<Navigate to="/shopping" replace />} />

                        {/* Protected Routes */}
                        <Route element={<ProtectedRoute />}>
                            <Route path="/result/:taskId" element={<ResultPage />} />
                            <Route path="mirror" element={<MirrorPage />} />
                            <Route path="dresser" element={<BrandPage />} />
                            <Route path="style-meter" element={<StyleMeterPage />} />
                            <Route path="outfit-chooser" element={<HelpMeChoosePage />} />
                            <Route path="inventory" element={<InventoryPage />} />
                            <Route path="add-item" element={<AddItemPage />} />
                            <Route path="profile" element={<MyProfilePage />} />
                            <Route path="measurements" element={<MeasurementsPage />} />
                        </Route>
                    </Route>

                    {/* Fallback Route */}
                    <Route path="*" element={<Navigate to="/mirror" replace />} />
                </Routes>
            </Router>
        </EventProvider>
    );
};

export default App;
