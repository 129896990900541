// src/pages/MeasurementsPage.js

import React, { useState, useEffect, useRef } from 'react';
import ApiService from '../services/apiService';
import Message from '../components/Message';
import useAutoDismissMessage from '../hooks/useAutoDismissMessage';
import TopMenu from '../components/TopMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import '../styles/pages/measurements.css';

const MeasurementsPage = () => {
    const [measurements, setMeasurements] = useState({
        // Numeric Measurements
        height: '',             // Height in cm
        weight: '',             // Weight in kg
        chest: '',              // Chest circumference in cm
        waist: '',              // Waist circumference in cm
        hips: '',               // Hips circumference in cm
        inseam: '',             // Inseam length in cm
        sleeve_length: '',      // Sleeve length in cm
        neck_circumference: '', // Neck circumference in cm
        shoulder_width: '',     // Shoulder width in cm
        arm_length: '',         // Arm length in cm
        leg_length: '',         // Leg length in cm
        shoe_size: '',          // Shoe size (US sizes)

        // Personal Attributes
        age: '',                // Age in years
        gender: '',             // 'male', 'female', 'non-binary', 'other'
        body_type: '',          // 'slim', 'athletic', 'average', 'curvy', 'plus-size'
        skin_tone: '',          // 'fair', 'light', 'medium', 'tan', 'dark'
        hair_color: '',         // e.g., 'brown', 'blonde'
        eye_color: '',          // e.g., 'blue', 'green'
        clothing_size: '',      // 'XS', 'S', 'M', 'L', 'XL', 'XXL'
        preferred_style: '',    // 'casual', 'formal', 'sporty', etc.
        notable_features: '',   // e.g., 'tattoo on left arm'

        // Additional fields if any
    });

    const [taskId, setTaskId] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);

    // Use the custom hook for messages
    const { message, type, showMessage, clearMessage } = useAutoDismissMessage();

    // Function to clean measurement values
    const cleanMeasurements = (data) => {
        // Define numeric fields
        const numericFields = [
            'height',
            'weight',
            'chest',
            'waist',
            'hips',
            'inseam',
            'sleeve_length',
            'neck_circumference',
            'shoulder_width',
            'arm_length',
            'leg_length',
            'shoe_size',
            'age',
        ];

        const cleanedMeasurements = {};
        for (const [key, value] of Object.entries(data)) {
            if (numericFields.includes(key)) {
                if (typeof value === 'string') {
                    // Remove non-numeric characters
                    const numericValue = value.replace(/[^\d.-]/g, '');
                    cleanedMeasurements[key] = numericValue === '' ? '' : parseFloat(numericValue);
                } else {
                    cleanedMeasurements[key] = value;
                }
            } else {
                // For non-numeric fields, keep the value as is
                cleanedMeasurements[key] = value;
            }
        }
        return cleanedMeasurements;
    };

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        (async () => {
            try {
                const response = await ApiService.getMeasurements();
                if (isMounted && response.measurements) {
                    const cleanedMeasurements = cleanMeasurements(response.measurements);
                    setMeasurements(cleanedMeasurements);
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching measurements:', error);
                    showMessage('Error fetching measurements.', 'error');
                }
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        })();

        return () => {
            isMounted = false;
        };
    }, []);

    const handleUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files) {
            setImages((prevImages) => [...prevImages, ...files]);
        }
    };

    const handleImageRemove = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMeasurements((prevMeasurements) => ({
            ...prevMeasurements,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await ApiService.saveMeasurements(taskId, measurements);
            console.log('Measurements saved:', response);
            showMessage('Measurements saved successfully!', 'success');
        } catch (error) {
            console.error('Error uploading measurements:', error);
            showMessage('Error uploading measurements.', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleAnalyse = async () => {
        if (images.length === 0) {
            showMessage('Please upload at least one image.', 'error');
            return;
        }

        setLoading(true);
        try {
            const result = await ApiService.uploadMeasurements(images, measurements);
            console.log('Analysis:', result);
            if (result.measurements) {
                const cleanedMeasurements = cleanMeasurements(result.measurements);
                setMeasurements(cleanedMeasurements);
                showMessage('Analysis completed successfully!', 'success');
            } else {
                showMessage('No measurements returned from analysis.', 'error');
            }
            setTaskId(result.task_id);
        } catch (error) {
            console.error('Error analysing images:', error);
            showMessage('Error analysing images.', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main-container d-flex flex-column align-items-center justify-content-start text-center p-3">
            <TopMenu />
            <div className="measurements-page">
                <h2>Personalization</h2>
                {message && <Message message={message} type={type} />}
                <p>Upload photos to get your measures and attributes.</p>
                <div className="controls">
                    <label className="btn btn-secondary">
                        Upload Photo
                        <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleUpload}
                            style={{ display: 'none' }}
                        />
                    </label>
                    <button
                        className="btn btn-primary"
                        onClick={handleAnalyse}
                        disabled={loading || images.length === 0}
                    >
                        {loading ? 'Processing...' : 'Analyse Image'}
                    </button>
                    <button
                        className="btn btn-success"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Save Measurements'}
                    </button>
                </div>

                {/* Image Preview */}
                {images.length > 0 && (
                    <div className="images-preview">
                        {images.map((image, index) => (
                            <div key={index} className="image-container">
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Measurement ${index + 1}`}
                                />
                                <button
                                    className="delete-image-button"
                                    onClick={() => handleImageRemove(index)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        ))}
                    </div>
                )}


                <form className="measurements-form">
                    {/* Personal Attributes Section */}
                    <h3>Personal Attributes</h3>
                    <div className="attributes-grid">
                        {/* Age */}
                        <div className="attribute-field">
                            <label htmlFor="age">Age (years)</label>
                            <input
                                type="number"
                                id="age"
                                name="age"
                                value={measurements.age}
                                onChange={handleInputChange}
                                placeholder="e.g., 28"
                            />
                        </div>
                        {/* Gender */}
                        <div className="attribute-field">
                            <label htmlFor="gender">Gender</label>
                            <select
                                id="gender"
                                name="gender"
                                value={measurements.gender}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="non-binary">Non-binary</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        {/* Body Type */}
                        <div className="attribute-field">
                            <label htmlFor="body_type">Body Type</label>
                            <select
                                id="body_type"
                                name="body_type"
                                value={measurements.body_type}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Body Type</option>
                                <option value="slim">Slim</option>
                                <option value="athletic">Athletic</option>
                                <option value="average">Average</option>
                                <option value="curvy">Curvy</option>
                                <option value="plus-size">Plus-size</option>
                            </select>
                        </div>
                        {/* Skin Tone */}
                        <div className="attribute-field">
                            <label htmlFor="skin_tone">Skin Tone</label>
                            <select
                                id="skin_tone"
                                name="skin_tone"
                                value={measurements.skin_tone}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Skin Tone</option>
                                <option value="fair">Fair</option>
                                <option value="light">Light</option>
                                <option value="medium">Medium</option>
                                <option value="tan">Tan</option>
                                <option value="dark">Dark</option>
                            </select>
                        </div>
                        {/* Hair Color */}
                        <div className="attribute-field">
                            <label htmlFor="hair_color">Hair Color</label>
                            <input
                                type="text"
                                id="hair_color"
                                name="hair_color"
                                value={measurements.hair_color}
                                onChange={handleInputChange}
                                placeholder="e.g., Brown"
                            />
                        </div>
                        {/* Eye Color */}
                        <div className="attribute-field">
                            <label htmlFor="eye_color">Eye Color</label>
                            <input
                                type="text"
                                id="eye_color"
                                name="eye_color"
                                value={measurements.eye_color}
                                onChange={handleInputChange}
                                placeholder="e.g., Blue"
                            />
                        </div>
                        {/* Clothing Size */}
                        <div className="attribute-field">
                            <label htmlFor="clothing_size">Clothing Size</label>
                            <select
                                id="clothing_size"
                                name="clothing_size"
                                value={measurements.clothing_size}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Size</option>
                                <option value="XS">XS</option>
                                <option value="S">S</option>
                                <option value="M">M</option>
                                <option value="L">L</option>
                                <option value="XL">XL</option>
                                <option value="XXL">XXL</option>
                            </select>
                        </div>
                        {/* Notable Features */}
                        <div className="attribute-field">
                            <label htmlFor="notable_features">Notable Features</label>
                            <input
                                type="text"
                                id="notable_features"
                                name="notable_features"
                                value={measurements.notable_features}
                                onChange={handleInputChange}
                                placeholder="e.g., Tattoo on left arm"
                            />
                        </div>
                    </div>

                    {/* Style Preferences Section */}
                    <h3>Style Preferences</h3>
                    <div className="preferences-grid">
                        {/* Preferred Style */}
                        <div className="preference-field">
                            <label htmlFor="preferred_style">Preferred Style</label>
                            <select
                                id="preferred_style"
                                name="preferred_style"
                                value={measurements.preferred_style}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Preferred Style</option>
                                <option value="casual">Casual</option>
                                <option value="formal">Formal</option>
                                <option value="sporty">Sporty</option>
                                <option value="trendy">Trendy</option>
                                <option value="classic">Classic</option>
                                <option value="bohemian">Bohemian</option>
                                <option value="preppy">Preppy</option>
                                <option value="punk">Punk</option>
                            </select>
                        </div>
                    </div>

                    {/* Physical Measurements Section */}
                    <h3>Physical Measurements</h3>
                    <div className="measurements-grid">
                        {/* Height */}
                        <div className="measurement-field">
                            <label htmlFor="height">Height (cm)</label>
                            <input
                                type="number"
                                id="height"
                                name="height"
                                value={measurements.height}
                                onChange={handleInputChange}
                                placeholder="e.g., 175"
                            />
                        </div>
                        {/* Weight */}
                        <div className="measurement-field">
                            <label htmlFor="weight">Weight (kg)</label>
                            <input
                                type="number"
                                id="weight"
                                name="weight"
                                value={measurements.weight}
                                onChange={handleInputChange}
                                placeholder="e.g., 70"
                            />
                        </div>
                        {/* Chest */}
                        <div className="measurement-field">
                            <label htmlFor="chest">Chest (cm)</label>
                            <input
                                type="number"
                                id="chest"
                                name="chest"
                                value={measurements.chest}
                                onChange={handleInputChange}
                                placeholder="e.g., 95"
                            />
                        </div>
                        {/* Waist */}
                        <div className="measurement-field">
                            <label htmlFor="waist">Waist (cm)</label>
                            <input
                                type="number"
                                id="waist"
                                name="waist"
                                value={measurements.waist}
                                onChange={handleInputChange}
                                placeholder="e.g., 80"
                            />
                        </div>
                        {/* Hips */}
                        <div className="measurement-field">
                            <label htmlFor="hips">Hips (cm)</label>
                            <input
                                type="number"
                                id="hips"
                                name="hips"
                                value={measurements.hips}
                                onChange={handleInputChange}
                                placeholder="e.g., 90"
                            />
                        </div>
                        {/* Inseam */}
                        <div className="measurement-field">
                            <label htmlFor="inseam">Inseam (cm)</label>
                            <input
                                type="number"
                                id="inseam"
                                name="inseam"
                                value={measurements.inseam}
                                onChange={handleInputChange}
                                placeholder="e.g., 80"
                            />
                        </div>
                        {/* Sleeve Length */}
                        <div className="measurement-field">
                            <label htmlFor="sleeve_length">Sleeve Length (cm)</label>
                            <input
                                type="number"
                                id="sleeve_length"
                                name="sleeve_length"
                                value={measurements.sleeve_length}
                                onChange={handleInputChange}
                                placeholder="e.g., 60"
                            />
                        </div>
                        {/* Neck Circumference */}
                        <div className="measurement-field">
                            <label htmlFor="neck_circumference">Neck Circumference (cm)</label>
                            <input
                                type="number"
                                id="neck_circumference"
                                name="neck_circumference"
                                value={measurements.neck_circumference}
                                onChange={handleInputChange}
                                placeholder="e.g., 38"
                            />
                        </div>
                        {/* Shoulder Width */}
                        <div className="measurement-field">
                            <label htmlFor="shoulder_width">Shoulder Width (cm)</label>
                            <input
                                type="number"
                                id="shoulder_width"
                                name="shoulder_width"
                                value={measurements.shoulder_width}
                                onChange={handleInputChange}
                                placeholder="e.g., 45"
                            />
                        </div>
                        {/* Arm Length */}
                        <div className="measurement-field">
                            <label htmlFor="arm_length">Arm Length (cm)</label>
                            <input
                                type="number"
                                id="arm_length"
                                name="arm_length"
                                value={measurements.arm_length}
                                onChange={handleInputChange}
                                placeholder="e.g., 60"
                            />
                        </div>
                        {/* Leg Length */}
                        <div className="measurement-field">
                            <label htmlFor="leg_length">Leg Length (cm)</label>
                            <input
                                type="number"
                                id="leg_length"
                                name="leg_length"
                                value={measurements.leg_length}
                                onChange={handleInputChange}
                                placeholder="e.g., 90"
                            />
                        </div>
                        {/* Shoe Size */}
                        <div className="measurement-field">
                            <label htmlFor="shoe_size">Shoe Size (US)</label>
                            <input
                                type="number"
                                id="shoe_size"
                                name="shoe_size"
                                value={measurements.shoe_size}
                                onChange={handleInputChange}
                                placeholder="e.g., 9"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MeasurementsPage;
