import React, { useState } from 'react';
import TypingEffect from 'react-typing-effect';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import config from '../config';
import '../styles/components/TextResult.css';

const TextResult = ({ textResult, taskId }) => {
    const [showReasoning, setShowReasoning] = useState(false);
    const [loadingReasoning, setLoadingReasoning] = useState(false);
    const [presentedText, setPresentedText] = useState(textResult);

    const handleShowReasoning = async () => {
        setLoadingReasoning(true);
        try {
            const response = await axios.get(`${config.backend_hostname}/reasoning/${taskId}`);
            setPresentedText(response.data.reasoning);
        } catch (error) {
            console.error('Failed to fetch reasoning:', error);
        } finally {
            setLoadingReasoning(false);
        }
        setShowReasoning(!showReasoning);
    };

    return (
        <div>
            <div className="text-result mt-3 w-100">
                <TypingEffect
                    text={textResult}
                    speed={50}
                    eraseDelay={1000000}
                    cursorRenderer={cursor => <h1>{cursor}</h1>}
                    displayTextRenderer={(text) => (
                        <div>
                            {text.split('\n').map((item, i) => (
                                <span key={i}>
                                    {item}
                                    <br />
                                </span>
                            ))}
                        </div>
                    )}
                />
                {/*{showReasoning && (*/}
                {/*    <div className="mt-3">*/}
                {/*        {loadingReasoning ? 'Loading reasoning...' : <ReactMarkdown>{presentedText}</ReactMarkdown>}*/}
                {/*    </div>*/}

                {/*)}*/}
                {/*<div className="mt-3">*/}
                {/*    <button*/}
                {/*        className="btn btn-primary"*/}
                {/*        onClick={handleShowReasoning}*/}
                {/*    >*/}
                {/*        {showReasoning ? 'Hide' : 'Show Reasoning'}*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default TextResult;
