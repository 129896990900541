// File: src/components/EventSelection.js
import React, { useState, useEffect, useContext } from 'react';
import EventContext from '../context/EventContext';

const EventSelection = () => {
  const { event, setEvent } = useContext(EventContext); // Access global event state and setter
  const [customEvent, setCustomEvent] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);

  const predefinedEvents = [
    'Casual', 'Weekend', 'Day Out', 'Relaxed',
    'Formal', 'Work', 'Business Meeting', 'Wedding', 'Pool', 'Beach',
    'Party', 'Birthday', 'Club', 'Dinner Party', 'Sports'
  ];

  useEffect(() => {
    if (!event) {
      setEvent('Casual'); // Set default event globally on mount
    }
  }, [event, setEvent]);

  const handleEventChange = (e) => {
    const value = e.target.value;
    if (value === 'Other') {
      setShowCustomInput(true);
    } else {
      setShowCustomInput(false);
      setEvent(value); // Update global event directly
    }
  };

  const handleCustomSubmit = (e) => {
    e.preventDefault();
    if (customEvent.trim()) {
      setEvent(customEvent); // Update global event with custom input
      setCustomEvent(''); // Clear custom input field
      setShowCustomInput(false); // Hide custom input field
    }
  };

  return (
      <div>
        <select
            className="page-select"
            value={event}
            onChange={handleEventChange}
        >
          <option value="" disabled>Select an Event</option>
          {predefinedEvents.map((eventOption, index) => (
              <option key={index} value={eventOption}>{eventOption}</option>
          ))}
          <option value="Other">Other</option>
        </select>

        {showCustomInput && (
            <form onSubmit={handleCustomSubmit} className="custom-event-form">
              <input
                  type="text"
                  value={customEvent}
                  onChange={(e) => setCustomEvent(e.target.value)}
                  required
              />
            </form>
        )}
        <br />
      </div>
  );
};

export default EventSelection;
