// src/hooks/useAutoDismissMessage.js
import { useState, useEffect, useRef } from 'react';

const useAutoDismissMessage = (timeout = 5000) => {
    const [messageData, setMessageData] = useState({
        message: '',
        type: '', // 'success' or 'error'
    });
    const timerRef = useRef(null);

    const showMessage = (msg, type = 'success', duration = timeout) => {
        setMessageData({ message: msg, type });

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
            setMessageData({ message: '', type: '' });
            timerRef.current = null;
        }, duration);
    };

    const clearMessage = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
        setMessageData({ message: '', type: '' });
    };

    useEffect(() => {
        // Cleanup function to clear the timer if component unmounts
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    return { ...messageData, showMessage, clearMessage };
};

export default useAutoDismissMessage;
