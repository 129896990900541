// File: src/pages/LoginPage.js
import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import config from '../config';
import '../styles/login.css';

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const googleButtonRef = useRef(null);

    // Extract nextUrl from the query parameters (defaults to "/mirror" if not provided)
    const searchParams = new URLSearchParams(location.search);
    const nextUrl = searchParams.get('nextUrl') || '/mirror';

    const handleCredentialResponse = (response) => {
        try {
            const decodedToken = jwtDecode(response.credential);

            Cookies.set('user_email', decodedToken.email, { expires: 3, secure: true, sameSite: 'strict' });
            Cookies.set('google_id_token', response.credential, { expires: 3, secure: true, sameSite: 'strict' });

            // Redirect to the nextUrl after successful login
            navigate(nextUrl, { replace: true });
        } catch (error) {
            console.error('Error decoding token:', error);
            alert('Failed to authenticate. Please try again.');
        }
    };

    useEffect(() => {
        const token = Cookies.get('google_id_token');
        if (token) {
            navigate(nextUrl, { replace: true });
            return;
        }

        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            window.google.accounts.id.initialize({
                client_id: config.googleClientId,
                callback: handleCredentialResponse,
                auto_select: false,
            });

            window.google.accounts.id.renderButton(
                googleButtonRef.current,
                {
                    theme: 'outline',
                    size: 'large',
                    width: '100%',
                }
            );
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [navigate, nextUrl]);

    return (
        <div className="login-container">
            <div className="login-background"></div>
            <div className="container d-flex align-items-center justify-content-center min-vh-100">
                <div
                    className="card p-4 shadow-lg bg-dark text-white"
                    style={{ maxWidth: '500px', width: '100%' }}
                >
                    <div className="card-body text-center">
                        <img src="/images/decorative.svg" alt="Magic Mirror AI Logo" className="logo mb-3" />
                        <h2 className="card-title mb-4">Welcome to Magic Mirror AI</h2>
                        <p className="card-text">
                            Step into a world where technology meets style. At Magic Mirror AI, we’re redefining the mirror experience with intelligent insights and personal styling recommendations that integrate seamlessly into your daily life.
                        </p>
                        <hr className="my-4" />
                        <p className="mt-4">Log in with your Google account to start your journey with Magic Mirror AI.</p>
                        <div ref={googleButtonRef} className="mb-3 w-100 google-button-container"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
