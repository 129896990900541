import React, { useState, useRef } from 'react';
import CommonPage from './CommonPage';

const BrandPage = () => {
  const promptOptions = [
    { value: 'zara', label: 'Zara 🌟' },
    { value: 'my_wardrobe', label: 'My Wardrobe 🧥' },
    { value: 'nike', label: 'Nike 👟' },
    { value: 'gucci', label: 'Gucci 👑' },
  ];

  return (
      <div>
        <CommonPage
            promptOptions={promptOptions}
            fetchRecommendations={true}
            pageDescription="Get Dressed with Style!"
        />
      </div>
  );
};

export default BrandPage;
